import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/shahid/Desktop/Projects/Personal/madrasatulilm.com/src/views/layouts/MainLayout.js";
import { Helmet } from 'react-helmet';
import { Link } from 'gatsby';
import { Box, Breadcrumb, Divider, SEO, Text } from '../../views/components';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO pageTitle="এখানে পড়তে খরচ কত হবে" mdxType="SEO" />
    <Box maxWidth={960} margin="0 auto" padding={{
      xs: 3,
      sm: 4
    }} mdxType="Box">
  <Text variant="h2" textAlign="center" mdxType="Text">
    এখানে পড়তে খরচ কত হবে
  </Text>
  <Divider mdxType="Divider" />
  <Breadcrumb links={[{
        url: '/',
        name: 'নীড়পাতা'
      }, {
        url: '/finance/',
        name: 'আর্থিক ব্যাপার'
      }]} mdxType="Breadcrumb" />
  <Divider mdxType="Divider" />
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": null
            }}>{`বালক/বালিকা`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`আবাসিক/অনাবাসিক`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`বেতন ও খরচ`}{`*`}</th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`বালক`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`আবাসিক`}</td>
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`বেতন:`}</strong><br />{` - প্লে-গ্রুপ: ১০০০ টাকা`}<br />{` - নার্সারী থেকে ক্লাস-২: ১৫০০ টাকা`}<br />{` - ক্লাস-৩ থেকে ক্লাস-৫: ২০০০ টাকা`}<br /><br />{` `}<strong parentName="td">{`খাবার খরচ`}</strong>{`: ২০০০ টাকা`}<br />{` `}<strong parentName="td">{`বোর্ডিং ফি`}</strong>{`: ১৫০০ টাকা`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`বালক`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`অনাবাসিক`}<br />{` (সময়: ফজরের পর থেকে যোহর পর্যন্ত এবং মাগরিব থেকে ইশা পর্যন্ত)`}</td>
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`বেতন:`}</strong><br />{` - প্লে-গ্রুপ: ১০০০ টাকা`}<br />{` - নার্সারী থেকে ক্লাস-২: ১৫০০ টাকা`}<br />{` - ক্লাস-৩ থেকে ক্লাস-৫: ২০০০ টাকা`}<br /><br />{` - সকালের নাস্তা: ৫০০ টাকা`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`বালিকা শাখা`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`অনাবাসিক`}{`*`}{`*`}<br />{` (সময়: সকাল থেকে যোহর পর্যন্ত এবং মাগরিব থেকে ইশা পর্যন্ত)`}</td>
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`বেতন:`}</strong><br />{` - প্লে-গ্রুপ: ১০০০ টাকা`}<br />{` - নার্সারী থেকে ক্লাস-২: ১৫০০ টাকা`}<br />{` - ক্লাস-৩ থেকে ক্লাস-৫: ২০০০ টাকা`}<br /></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`বৈকালিক পার্ট-টাইম মাদ্রাসা`}</td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`বেতন: ১০০০ টাকা`}</td>
          </tr>
        </tbody>
      </table>
      <h4 {...{
        "id": "ভর্তি-ফি-সবার-জন্য-২০০০-টাকা",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h4" {...{
          "href": "#%E0%A6%AD%E0%A6%B0%E0%A7%8D%E0%A6%A4%E0%A6%BF-%E0%A6%AB%E0%A6%BF-%E0%A6%B8%E0%A6%AC%E0%A6%BE%E0%A6%B0-%E0%A6%9C%E0%A6%A8%E0%A7%8D%E0%A6%AF-%E0%A7%A8%E0%A7%A6%E0%A7%A6%E0%A7%A6-%E0%A6%9F%E0%A6%BE%E0%A6%95%E0%A6%BE",
          "aria-label": "ভর্তি ফি সবার জন্য ২০০০ টাকা permalink",
          "className": "header-anchor-icon before"
        }}><svg parentName="a" {...{
            "version": "1.1",
            "x": "0px",
            "y": "0px",
            "viewBox": "0 0 162.656 162.656",
            "xmlSpace": "preserve",
            "width": "0.6em",
            "height": "0.6em",
            "fill": "#2b9348",
            "stroke": "#2b9348",
            "strokeWidth": "0"
          }}><path parentName="svg" {...{
              "d": "M151.764,10.894c-14.522-14.522-38.152-14.525-52.676-0.008l0.003,0.003L76.112,33.872l10.607,10.605l22.983-22.988 l-0.002-0.002c8.678-8.663,22.785-8.658,31.457,0.014c8.673,8.672,8.672,22.786,0,31.461l-34.486,34.484 c-4.201,4.202-9.787,6.516-15.729,6.516c-5.942,0-11.529-2.314-15.73-6.516L64.605,98.052c7.035,7.035,16.389,10.91,26.338,10.91 c9.949,0,19.303-3.875,26.335-10.91l34.487-34.484C166.284,49.043,166.284,25.413,151.764,10.894z"
            }}></path>{` `}<path parentName="svg" {...{
              "d": "M52.96,141.162L52.96,141.162c-8.675,8.67-22.788,8.668-31.461-0.005c-8.673-8.675-8.673-22.791-0.001-31.465L55.98,75.21 c8.675-8.674,22.789-8.674,31.462,0L98.05,64.604c-14.524-14.523-38.154-14.524-52.676,0L10.89,99.086 c-14.519,14.523-14.519,38.154,0.001,52.678c7.263,7.262,16.801,10.893,26.341,10.892c9.536,0,19.074-3.629,26.333-10.887 l0.002-0.001l22.984-22.99l-10.608-10.606L52.96,141.162z"
            }}></path>{` `}</svg></a>{`ভর্তি ফি সবার জন্য ২০০০ টাকা`}</h4>
      <h5 {...{
        "id": "আর-প্রতি-বছর-নতুন-করে-আরো-১০০০-টাকা",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h5" {...{
          "href": "#%E0%A6%86%E0%A6%B0-%E0%A6%AA%E0%A7%8D%E0%A6%B0%E0%A6%A4%E0%A6%BF-%E0%A6%AC%E0%A6%9B%E0%A6%B0-%E0%A6%A8%E0%A6%A4%E0%A7%81%E0%A6%A8-%E0%A6%95%E0%A6%B0%E0%A7%87-%E0%A6%86%E0%A6%B0%E0%A7%8B-%E0%A7%A7%E0%A7%A6%E0%A7%A6%E0%A7%A6-%E0%A6%9F%E0%A6%BE%E0%A6%95%E0%A6%BE",
          "aria-label": "আর প্রতি বছর নতুন করে আরো ১০০০ টাকা permalink",
          "className": "header-anchor-icon before"
        }}><svg parentName="a" {...{
            "version": "1.1",
            "x": "0px",
            "y": "0px",
            "viewBox": "0 0 162.656 162.656",
            "xmlSpace": "preserve",
            "width": "0.6em",
            "height": "0.6em",
            "fill": "#2b9348",
            "stroke": "#2b9348",
            "strokeWidth": "0"
          }}><path parentName="svg" {...{
              "d": "M151.764,10.894c-14.522-14.522-38.152-14.525-52.676-0.008l0.003,0.003L76.112,33.872l10.607,10.605l22.983-22.988 l-0.002-0.002c8.678-8.663,22.785-8.658,31.457,0.014c8.673,8.672,8.672,22.786,0,31.461l-34.486,34.484 c-4.201,4.202-9.787,6.516-15.729,6.516c-5.942,0-11.529-2.314-15.73-6.516L64.605,98.052c7.035,7.035,16.389,10.91,26.338,10.91 c9.949,0,19.303-3.875,26.335-10.91l34.487-34.484C166.284,49.043,166.284,25.413,151.764,10.894z"
            }}></path>{` `}<path parentName="svg" {...{
              "d": "M52.96,141.162L52.96,141.162c-8.675,8.67-22.788,8.668-31.461-0.005c-8.673-8.675-8.673-22.791-0.001-31.465L55.98,75.21 c8.675-8.674,22.789-8.674,31.462,0L98.05,64.604c-14.524-14.523-38.154-14.524-52.676,0L10.89,99.086 c-14.519,14.523-14.519,38.154,0.001,52.678c7.263,7.262,16.801,10.893,26.341,10.892c9.536,0,19.074-3.629,26.333-10.887 l0.002-0.001l22.984-22.99l-10.608-10.606L52.96,141.162z"
            }}></path>{` `}</svg></a>{`আর প্রতি বছর নতুন করে আরো ১০০০ টাকা`}</h5>
      <ul>
        <li parentName="ul">{`সামর্থ্যহীন পরিবার (সম্পূর্ণ বা আংশিক) মাসিক বেতন এর টাকার জন্য মাদ্রাসাতুল ইলম এর গুরাবা ফান্ড বরাবর আবেদন করতে পারেন।`}</li>
        <li parentName="ul">{`বালিকা শাখায় কোনো নাস্তার ব্যবস্থা না থাকায়, অভিভাবকরা ছাত্রীদের সাথে নাস্তা দিয়ে দিবেন। তবে বাইরে থেকে কেনা চটকদার মোড়কের কেইক, চিপ্স, চকলেট, কোমল পানীয় ইত্যাদি মাদ্রাসায় আনা নিষিদ্ধ।`}</li>
      </ul>
    </Box>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      